import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import LaptopVideo from "../videos/0039_laptop_finale_3.webm";
import MobileVideo from "../videos/0039_mobile_finale_3.webm";
import { StaticImage } from "gatsby-plugin-image";
// import Header from "../components/header";
// markup
const IntroPage = ({ data }) => {
  const videoRef = React.createRef();
  const sourceRef = React.createRef();
  const clickMeBtn = React.createRef();
  let isPlaying = false;
  let timeouter = null;
  let videoSource;

  const [canGo, setCanGo] = useState(true);
  const [isDesktop, setDesktop] = useState(false);
  const [showCircleText, setShowCircle] = useState(true);
  const [step, setStep] = useState(0);
  const [showBanner, setShowBanner] = useState(false);
  const [textToShow, setTextToShow] = useState(null);
  const [isEndingScene, setIsEndingScene] = useState(false);

  const callToActionText = [
    "Click again, 1 is not enough !",
    "Click again, there is no 2 without 3 !",
  ];

  const updateSource = (newVideoSource) => {
    if (newVideoSource !== videoSource) {
      videoSource = newVideoSource;
      videoRef.current.src = videoSource;
      videoRef.current.load();
    }
  };

  useEffect(() => {
    videoRef.current.removeAttribute("controls");
    if (typeof window !== "undefined") {
      setDesktop(window.innerWidth > 1024);
      const newVideoSource =
        window.innerWidth > 1024 ? LaptopVideo : MobileVideo;

      updateSource(newVideoSource);
    }
  }, []);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
    const newVideoSource = window.innerWidth > 1024 ? LaptopVideo : MobileVideo;
    updateSource(newVideoSource);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const video = (
    <video
      id="introVideo"
      ref={videoRef}
      src={videoSource}
      type="video/mp4"
      playsInline
    >
      {/* <source ref={sourceRef}  type="video/mp4"></source> */}
    </video>
  );

  const playFromFor = (from, seconds, cb) => {
    try {
      document.getElementById("introVideo").currentTime = from;
      const prom = document.getElementById("introVideo").play();
      if (prom) {
        prom.then((_) => {
          document.getElementById("introVideo").play();
        });
        prom.catch((e) => {
          console.log(e);
          navigate("/");
        });
      }
      isPlaying = false;
      setTimeout(() => {
        try {
          if (!isPlaying) {
            document.getElementById("introVideo").pause();
          }
          if (cb) cb();
        } catch (e) {
          console.error(e);
        }
      }, seconds * 1000);
    } catch (e) {
      console.error(e);
    }
  };

  const counterToText = () => {
    if(timeouter){
      clearTimeout(timeouter);
    }
    return setTimeout(() => {
      setShowBanner(true);
    }, 5000);
  };

  const execute = () => {
    isPlaying = true;
    setCanGo(false);
    setShowCircle(false);
    if (timeouter) {
      setShowBanner(false);
      clearTimeout(timeouter);
    }
    if (step < 3 && canGo) {
      if (step === 0) {
        timeouter = counterToText();
        playFromFor(3, 1, () => {
          setCanGo(true);
          setTextToShow(callToActionText[0]);
        });
      } else if (step === 1) {
        timeouter = counterToText();
        playFromFor(6, 1, () => {
          setCanGo(true);
          setTextToShow(callToActionText[1]);
        });
      } else if (step === 2) {
        setIsEndingScene(true);
        setShowBanner(false);
        clearTimeout(timeouter);
        playFromFor(9, 3, () => {
          navigate("/");
          setCanGo(true);
        });
      }
      setStep(step + 1);
    }
  };

  //Have a button then when clicked calls the function execute()
  return (
    <div class="min-h-screen  bg-black flex  justify-center ">
      {[video]}
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
        <div class="animate-spin-slow ">
          <StaticImage
            src="../images/clickme.png"
            height="320"
            placeholder="tracedSVG"
            quality="100"
            alt="head-logo"
            hidden={!showCircleText}
          ></StaticImage>
        </div>
      </div>
      <div
        class="absolute top-20 uppercase font-bold xl:text-3xl text-center text-white"
        style={{ visibility: showBanner && ! isEndingScene ? "visible" : "hidden" }}
      >
        {textToShow}
      </div>
      <div
        class="absolute bottom-20 uppercase font-bold xl:text-3xl text-center text-white"
        style={{ visibility: showBanner && !isEndingScene? "visible" : "hidden" }}
      >
        {textToShow}
      </div>
      <div
        onClick={execute}
        class="min-h-screen min-w-full absolute z-50"
      ></div>
    </div>
  );
};
export default IntroPage;
